import { initializeApp } from 'firebase/app'
import { addDoc, collection, getFirestore, doc, getDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_API_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore_db = getFirestore(app)
 

function addAppointment(data){
    try{
        addDoc(collection(firestore_db,"AppointmentSystem"),data)
        return('Appointment Successfully Added!')
    }
    catch(e) {
        return(e)
    }
}

export function removeAppointment(appointment_id){
    const docRef = doc(firestore_db,'AppointmentSystem',appointment_id)
    deleteDoc(docRef).catch(err => console.log(err))    //TODO: create a db where you add the error if needed (so you can keep track of it)
}

export async function setVerified(document_id){
    const data = {
        appointment_verified : true
    }

    const docRef = doc(firestore_db,'AppointmentSystem',document_id)
    updateDoc(docRef,data)
}

export async function setAccepted(document_id){
    const data = {
        refused : false,
        accepted : true
    }

    const docRef = doc(firestore_db,'AppointmentSystem',document_id)
    updateDoc(docRef,data)
}

export async function setRefused(document_id){
    const data = {
        refused : true,
        accepted : false
    }

    const docRef = doc(firestore_db,'AppointmentSystem',document_id)
    updateDoc(docRef,data)
}


export const appointmentCollectionRef = collection(firestore_db,'AppointmentSystem')

export default addAppointment;