import { onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import addClient, { collectionRef, removeClient,  } from './firebase_management'
import { Input, Textarea } from '@material-tailwind/react'

import { BsTrash } from 'react-icons/bs'

export default function ManagementSystemPage() {

  // get data from db
  const [clients,setClients] = useState([])

  useEffect(() => {
    const unsubscribe = onSnapshot(collectionRef,snapshop => {
      setClients(snapshop.docs.map(doc => (
        {
          id: doc.id,
          data : doc.data()
        }
      )))
    })
    return() => {
      unsubscribe()
    }
  }, [])


  return (
    <div className=''>

      <AddClient></AddClient>
      <Holder Clients= {clients}></Holder>

    </div>
  )
}



const Holder = ({Clients}) => {
  const grid_css = "grid lg:grid-cols-9 gap-2 text-center "
  const [clientId,setClientId] = useState('')

  return(
    <div className=''>
        <h1 className='text-[var(--colorPrimary)] text-3xl font-bold py-5' >Management System</h1>
        
        <div className=''>
          <div className='py-3'>Document ID : {clientId}</div>
          <ul id='headers' className={grid_css +" bg-gray-400 border-black border-[0.15rem] hidden lg:grid "}>
            <li>client_name</li>
            <li>client_adress</li>
            <li>client_postal_code</li>
            <li>client_city</li>
            <li>client_email</li>
            <li>client_website</li>
            <li>proposition_sent</li>
            <li>proposition_response</li>
            <li>delete</li>
          </ul>
          <div id='content'>
            <ul className='grid gap-5 lg:gap-0'>
              {
                Clients.map((client,key) => {
                  return(
                    <li key={key} className={grid_css +` hover:bg-blue-400 hover:text-white font-medium ${key%2?"bg-gray-300" : "bg-white"}`} onClick={()=>{setClientId(client.id)}}>
                      <div>{client.data.client_name}</div>
                      <div>{client.data.client_adress}</div>
                      <div>{client.data.client_postal_code}</div>
                      <div>{client.data.client_city}</div>
                      <div className='overflow-x-scroll'>{client.data.client_email}</div>
                      <div className='overflow-x-scroll'>{client.data.client_website}</div>
                      <div>{String(client.data.proposition_sent)}</div>
                      <div>{client.data.proposition_response}</div>
                      <div className='grid'><button onClick={() => {removeClient(client.id)}}><BsTrash className='my-auto text-3xl lg:text-2xl  hover:text-[var(--colorPrimary)] mx-auto  '/></button></div>
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>

      </div>
  )
}

const AddClient = () => {
  const [name,setName] = useState('')
  const [adress,setAdress] = useState('')
  const [postalCode,setPostalCode] = useState('')
  const [city,setCity] = useState('')
  const [email,setEmail] = useState('')
  const [website,setWebsite] = useState('')
  const [propositionSent,setPropositionSent] = useState(false)
  const [propositionResponse,setPropositionResponse] = useState('/')

  const [state,setState] = useState('')

  async function clearForm(waitingSeconds,e,setState){
    
    function timeout(delay){
        return new Promise(res => setTimeout(res,delay));
    }
    await timeout(waitingSeconds*1000);
    e.target.reset()
    setState("")
}

  const addClientToDB = (e) => {
    e.preventDefault()

    let data = {
      client_name : name,
      client_adress : adress,
      client_postal_code : postalCode,
      client_city : city,
      client_email : email,
      client_website : website,
      proposition_sent : propositionSent,
      proposition_response : propositionResponse,
    }
    
    let response = addClient(data) //firebase
    setState(response)
    clearForm(2,e,setState)

  
  }

  return(
    <div>
      <h1 className='text-[var(--colorPrimary)] text-3xl font-bold py-5'>Add Client</h1>
      <div className='max-w-md'>
        <p className='text-md text-[var(--colorSecondary)] font-bold'>{state}</p>
        <form className='grid gap-2' onSubmit={addClientToDB}>
          <Input label='Name' required onChange={(e) => {setName(e.target.value)}}></Input>
          <Input label='Adress' onChange={(e) => {setAdress(e.target.value)}}></Input>
          <Input label='Postal Code' onChange={(e) => {setPostalCode(e.target.value)}}></Input>
          <Input label='City' onChange={(e) => {setCity(e.target.value)}}></Input>
          <Input label='Email' onChange={(e) => {setEmail(e.target.value)}}></Input>
          <Input label='Website' onChange={(e) => {setWebsite(e.target.value)}}></Input>
          <div className='flex gap-2'>
            <label>Proposition Sent?</label>
            <input type='checkbox' onChange={(e) => {setPropositionSent(e.target.value? true : false)}}/>
          </div>
          <Textarea label='Proposition Response' defaultValue={"/"} onChange={(e) => {setPropositionResponse(e.target.value)}} ></Textarea>
          <button type='submit' className='w-32 py-3 bg-[var(--colorPrimary)] text-white font-medium text-xl'>Add client</button>
        </form>
      </div>
    </div>
  )
}