import React, { useState } from 'react'
import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'



export default function AdminHeader() {

    
    const [error,setError] = useState('')
    const { currentUser, logout } = useAuth()

    const redirect = useNavigate()

    async function handleLogout(){
        setError('')

        try {
            await logout()
            redirect('/login')
        } catch{
            setError('Failed to log out')
        }
    }

  return (
    <div className=''>

        <div className=' bg-white border-[0.05rem] shadow-md border-blue-500'>

            <div className='pt-5 grid justify-center gap-5 px-10 lg:pt-0 lg:gap-16 lg:grid-flow-col lg:justify-end'>
                <div className='flex'>
                    <p className="text-red-500">{error}</p>
                    <div className="flex gap-2">
                        <label className='my-auto'>User Email :</label>
                        <span className='my-auto'>{currentUser.email}</span>
                    </div>
                </div>
                <div className="grid">
                    <Link to='/dashboard' className='border-[0.15rem] border-blue-500 rounded-lg px-5 py-2
                 hover:text-white hover:bg-blue-500 duration-75
                font-medium text-center'>Dashboard</Link>
                </div>
                <div className="grid">
                    <Link to='/dashboard/update-profile' className='border-[0.15rem] border-blue-500 rounded-lg px-5 py-2
                 hover:text-white hover:bg-blue-500 duration-75
                font-medium text-center'>Update Profile</Link>
                </div>
                <div className='grid'>
                    <button onClick={handleLogout} disabled={false}
                        className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                        hover:text-white hover:bg-blue-500 duration-75
                        font-medium'>Log out</button>
                </div>
            </div>

        </div>

    </div>
  )
}
