import React from 'react'

import ProductsData from '../../data/products.json'
import Header from '../../components/en/Header'
import ProductCard from '../../components/global/ProductCard'

export default function ProductsPage() {

  window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

  const ProductCardHolder = () => {
    return(
      
      <div className='mx-10 my-10 flex flex-col gap-10
        lg:flex-row lg:justify-center'>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['en'].detailed_services_names} Service={ProductsData['en'].services[0]} Hightlight={false} Href={'/#/en/basicpackage'} ButtonName={"GET NOW"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"SPECIAL OFFER"} />
        </div>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['en'].detailed_services_names} Service={ProductsData['en'].services[1]} Hightlight={true} Href={'/#/en/propackage'} ButtonName={"GET NOW"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"SPECIAL OFFER"} />
        </div>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['en'].detailed_services_names} Service={ProductsData['en'].services[2]} Hightlight={false} Href={'/#/en/businesspackage'} ButtonName={"GET NOW"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"SPECIAL OFFER"} />
        </div>
      </div>
    )
  }


  return (
    <div id='products' className=''>
      <Header/>
      <div className='pt-10 pb-5 bg-[var(--colorBlack-15)] '>
        <div className='px-5 py-3'>
          <h1 className='font-ProWebS text-3xl font-bold mb-3
            sm:text-4xl
            md:text-5xl'> <span className='text-[var(--colorPrimary)]'>Start now</span> & don't look back </h1>
          <h3 className='font-ProWebS text-lg text-[var(--colorBlackBright-sm)]'>
            A better customization of your product can be made based on these packages. 
            </h3>
        </div>
        <ProductCardHolder  />
        
        <div className='text-sm mx-20'>
          <span>*Displayed prices are subject to change at the conclusion of the sales period.</span>
        </div>
      </div>

      {/* <AdditionalServices/> */}

    </div>
  )
}

const AdditionalServices = () => {
  return(
    <div className='md:container md:mx-auto my-10 '>
        <div className='bg-[var(--colorPrimary)] py-3 px-2 flex flex-col'>
          <h1 className='text-white text-3xl font-bold'> Additional Services </h1>
          <p className='text-xs text-white'>These services are addons for the basic packages</p>
        </div>
        <div className='flex flex-col gap-5
              md:grid md:grid-flow-col '>
          <HostingAddon/>
          <SEOAddon/>
          <DesignAddon/>
        </div>
      </div>
  )
}


const HostingAddon = () => {
  const HostCard = ({Name,Price,Valute,BillingType}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-md)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}/month</span></h1>
        </div>
        <div>
          <p className='text-xs'>Billed {BillingType}</p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>Hosting</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <HostCard Name={"Solo Pack"} Price={''} Valute={'€'} BillingType={'monthly'} />
          <HostCard Name={'Three Friends Pack'} Price={''} Valute={'€'} BillingType={'every 3 months'} />
          <HostCard Name={'Trust Pack'} Price={''} Valute={'€'} BillingType={'anually'} />
        </div>
      </div>
    </div>
  )
}

const SEOAddon = () => {
  const SEOCard = ({Name,Price,Valute,Description}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-sm)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}</span></h1>
        </div>
        <div>
          <p className='text-sm'>
            {Description}
          </p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>SEO</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <SEOCard Name={"Basic"} Price={''} Valute={'€'} Description={"Default SEO generated for your page."} />
          <SEOCard Name={'Starter'} Price={''} Valute={'€'} Description={"Researched SEO, optimised content and metadata."}/>
          <SEOCard Name={'Best experience'} Price={''} Valute={'€'} Description={"Starter + deep research, monthly testing and more."} />
        </div>
      </div>
    </div>
  )
}


const DesignAddon = () => {
  const DesignCard = ({Name,Price,Valute,PerPage,Description}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-md)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}{PerPage?<span className='text-sm'>/page</span>:<></>}</span></h1>
        </div>
        <div>
          <p className='text-sm'>
            {Description}
          </p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>Design</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <DesignCard Name={"Responsive"} PerPage={true} Price={''} Valute={'€'} Description={""} />
          <DesignCard Name={'Animated'} PerPage={true} Price={''} Valute={'€'} Description={""}/>
          <DesignCard Name={'Custom Design'} PerPage={true} Price={''} Valute={'€'} Description={""} />
        </div>
      </div>
    </div>
  )
}