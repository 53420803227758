import React from 'react'
import Login from '../../components/global/Login'

export default function LoginPage() {
  return (
    <div className='h-screen'>

      <div className='grid'>
        <Login/>
      </div>

    </div>
  )
}
