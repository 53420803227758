
import React, { useEffect, useState } from 'react'

import { onSnapshot } from 'firebase/firestore'
import { appointmentCollectionRef, removeAppointment } from './firebase_appointmentSystem_Solstice'

import { ChevronUpDownIcon } from "@heroicons/react/24/outline";


import {
  Card,
  CardHeader,
  Typography,
  CardBody,
  Chip,
  CardFooter,
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

import { AiFillCheckCircle,AiFillCloseCircle } from 'react-icons/ai'
import {BsTrash} from 'react-icons/bs'

import { setAccepted, setRefused, setVerified } from './firebase_appointmentSystem_Solstice';


export default function AppointmentAdmin() {
  
  const [appointments, setAppointments] = useState([])

  useEffect(()=>{
      
      const unsubscribe = onSnapshot(appointmentCollectionRef,snapshot => {
          setAppointments(snapshot.docs.map(doc => (
              {
                  id: doc.id,
                  data : doc.data()
              }
              )))
      })

      return() => {
          unsubscribe()
      }
  },[])

  return (
    <div className='h-screen bg-gray-200'>
        <div className='pt-10'>
            <h1 className='text-2xl font-bold text-blue-500 text-center'>Appointment Page</h1>
            <AppointmentCard AppointmentsList={appointments}/>
        </div>
    </div>
  )
}

const TABLE_HEAD = ["Email", "Comments" ,"Date", "Hour", "Persons","Accept/Refuse","Delete"];
 
 
function AppointmentCard({AppointmentsList}) {
    const [open, setOpen] = useState(1);
 
    const handleOpen = (value) => {
      setOpen(open === value ? 0 : value);
    };

    const [showVerifiedAppointments,setShowVerifiedAppointments] = useState(true)

    function handleCheckbox (e) {
      setShowVerifiedAppointments(e.target.checked)
    }


  return (
    <Card className="h-full w-full">
      <CardHeader floated={false} shadow={false} className="rounded-none">
        <div className="flex items-center gap-8">
          <div>
            <Typography variant="h5" color="blue-gray">
              Appointments list
            </Typography>
          </div>
        </div>
      </CardHeader>
      <CardBody className="overflow-scroll px-0">
        <div className='mx-10 flex gap-5'>
          <div className='flex gap-2'>
            <input id='hide_verified_appointments' defaultChecked='true' type='checkbox' onChange={handleCheckbox}></input>
            <span>Hide answeread appointments</span>
          </div>
        </div>
        <table className="mt-4 w-full min-w-max table-auto text-left">
          <thead>
            <tr>
              {TABLE_HEAD.map((head, index) => (
                <th
                  key={head}
                  className="cursor-pointer border-y border-blue-gray-100 bg-blue-gray-50/50 p-4 transition-colors hover:bg-blue-gray-50"
                >
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="flex items-center justify-between gap-2 font-normal leading-none opacity-70"
                  >
                    {head}{" "}
                    {index !== TABLE_HEAD.length - 1 && (
                      <ChevronUpDownIcon strokeWidth={2} className="h-4 w-4" />
                    )}
                  </Typography>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {AppointmentsList.map((value, index) => {
              const isLast = index === AppointmentsList.length - 1;
              const classes = isLast ? "p-4" : "p-4 border-b border-blue-gray-50";
              

              function handleAccept(e){
                e.preventDefault();
                setVerified(value.id)
                value.data.appointment_verified = true
                setAccepted(value.id)
                value.data.accepted = true
                value.data.refused = false

                // do something - send email notification

              }
              function handleRefuse(e){
                e.preventDefault();
                setVerified(value.id)
                value.data.appointment_verified = true
                setRefused(value.id)
                value.data.accepted = false
                value.data.refused = true

                // do something - send email notification

              }
              
              function handleDelete(appointment_id){
                
                removeAppointment(appointment_id)
              }

              return (

                <tr key={value.id} className={value.data.appointment_verified? `bg-gray-300 ${showVerifiedAppointments? 'hidden':''}` : ''}>
                  <td className={classes}>
                    <Accordion open={open === index+1} >
                        <AccordionHeader onClick={() => handleOpen(index+1)} className='' >
                            <Typography variant="small" color="blue-gray" className="font-normal text-lg">
                                {value.data.email}
                            </Typography>
                        </AccordionHeader>
                        <AccordionBody>
                            <p className='font-normal'>
                                {value.data.comments}
                            </p>
                        </AccordionBody>
                    </Accordion>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col mx-auto">
                      <Chip variant={value.data.comments.length>0 ? 'filled' : 'ghost'} value='Comments !' color={value.data.comments.length>0 ? 'blue' : 'red'} className='mr-auto text-white'/>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="flex flex-col">
                      <Typography variant="small" color="blue-gray" className="font-normal">
                        {value.data.appointment_date}
                      </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <div className="w-max">
                        <Typography variant="small" color="blue-gray" className="font-normal">
                            {value.data.appointment_hour}
                        </Typography>
                    </div>
                  </td>
                  <td className={classes}>
                    <Typography variant="small" color="blue-gray" className="font-normal">
                      {value.data.persons_number}
                    </Typography>
                  </td>
                  <td className={classes}>
                    <div className='flex'>
                        <button onClick={handleRefuse}  disabled={value.data.appointment_verified?true:false} className={'my-auto text-4xl hover:text-red-500 '+ (value.data.refused && !value.data.accepted ? 'text-red-500' : 'text-red-300')}>
                            <AiFillCloseCircle/>
                        </button>
                        <div className='text-3xl my-auto text-gray-500'>
                            <span>/</span>
                        </div>
                        <button onClick={handleAccept} disabled={value.data.appointment_verified?true:false} className={'my-auto text-4xl hover:text-green-500 ' + (value.data.accepted && !value.data.refused ? 'text-green-500' : 'text-green-300')}>
                            <AiFillCheckCircle/>
                        </button>
                    </div>
                  </td>
                  <td className={classes}>
                    <button onClick={() => handleDelete(value.id)} className='text-3xl text-center hover:text-yellow-900 transition-all duration-75'>
                      <BsTrash/>
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </CardBody>
      <CardFooter className="flex items-center justify-between border-t border-blue-gray-50 p-4">

      </CardFooter>
    </Card>
  );
}
