import React,{ useState } from "react"

import {BsCheckCircle,BsXCircle} from 'react-icons/bs'
import {MdKeyboardArrowUp,MdKeyboardArrowDown} from 'react-icons/md'



const ProductCard = ({DetailedServicesNames,Service,Hightlight,Href,ButtonName,ButtonState,SpecialOffer,SpecialOfferText}) => {
    const [seeMoreState,setSeeMoreState] = useState('space-y-3 flex flex-col max-h-[300px] overflow-hidden')
    const [clicked,setClicked] = useState(false)

    function handleSeeMore () {
      if(!clicked){
        setSeeMoreState('space-y-3 flex flex-col max-h-none overflow-visible')
      }
      else{
        setSeeMoreState('space-y-3 flex flex-col max-h-[300px] overflow-hidden')
      }
      setClicked(!clicked)
    }

    return(
      <div className='font-ProWebS shadow-lg shadow-[var(--colorBlack-35)]
          bg-white
           pt-0 pb-10
          max-w-[300px]'>
        <div className={SpecialOffer ? "bg-red-700" : "hidden bg-red-700"}>
          <h4 className='text-white text-center tracking-widest font-medium'>{SpecialOfferText}</h4>
        </div>
        <div className='p-5'>
          <h1 className='text-center text-4xl tracking-widest'>{Service.name}</h1>
        </div>
        {
          Hightlight ?         
          <div className='px-10 flex justify-center py-5 mb-3 bg-[var(--colorPrimary)] text-white'>
            <h1 className='text-center text-6xl font-bold'>{Service.price}</h1>
            <span className='mt-auto text-3xl'>{Service.valute}</span>
            <p className='text-xs'>
            *HTT
          </p>
          </div> : 
          <div className='px-10 flex justify-center py-5 mb-3'>
            <h1 className='text-center text-6xl font-bold'>{Service.price}</h1>
            <span className='mt-auto text-3xl'>{Service.valute}</span>
            <p className='text-xs'>
            *HTT
          </p>
          </div>
        }

        <div className='px-5'>
          <div className={seeMoreState}>
            <div className='grid gap-2'>
              {
                Service.data.map((value,key) => {
                  let isBool = false;
                  value === Boolean(value) ? isBool = true : isBool = false;

                  if(isBool){
                    return(
                      <div className='flex gap-3'>
                        <div className='my-auto'>
                          {value ? <BsCheckCircle className='text-green-500 text-2xl'/> : <BsXCircle className='text-red-500 text-2xl'/>}
                        </div>
                        <div>
                          {DetailedServicesNames[key]}
                        </div>
                      </div>
                    )
                  }
                  else if (value <= 3 ){
                    return(
                      <div className='flex gap-3'>
                        <div className='my-auto'>
                          {
                            <BsCheckCircle className='text-yellow-700 text-2xl'/>
                          }
                        </div>
                        <div className='text-center'>
                          {value + " " +DetailedServicesNames[key]}
                        </div>
                        
                      </div>
                    )
                  }

                  else{
                    return(
                      <div className='flex gap-3'>
                        <div className='my-auto'>
                          {
                            value==='Free'? 
                              <BsCheckCircle className='text-yellow-700 text-2xl'/> :
                              <BsCheckCircle className='text-green-500 text-2xl'/>
                              
                          }
                        </div>
                        <div className='text-center'>
                          {value + " " +DetailedServicesNames[key]}
                        </div>
                        
                      </div>
                    )
                  }
                })
                
              }
            </div>

          </div>
          <div className='grid justify-center'>
            <button onClick={handleSeeMore} className='text-2xl text-[var(--colorBlack)]'>
              {
                clicked ? <MdKeyboardArrowUp className='hover:text-[var(--colorPrimary)] transition-all duration-75' /> : <MdKeyboardArrowDown className='hover:text-[var(--colorPrimary)] transition-all duration-75' />
              }
            </button>
          </div>
        </div>
        <div className={ButtonState ? "grid my-5" : "hidden"}>
          <a href={Href} className='button-buy mx-auto'>
            {ButtonName}
          </a>
        </div>
      </div>
    )
  }



export default ProductCard;