import React, { useRef, useState } from 'react'

import emailjs from '@emailjs/browser';

import { Button, Input,Textarea} from "@material-tailwind/react";

export default function FrContactForm() {

  const [messageStatus,setMessageStatus] = useState('')

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    var SERVICE_ID = process.env.REACT_APP_CONTACT_SERVICE_ID
    var TEMPLATE_ID = process.env.REACT_APP_CONTACT_TEMPLATE_ID
    var PUBLIC_API_KEY = process.env.REACT_APP_CONTACT_PUBLIC_API_KEY


    emailjs.sendForm(
        SERVICE_ID,
        TEMPLATE_ID,
        form.current,
        PUBLIC_API_KEY
      )
      .then((result) => {
          console.log(result.text);

          setMessageStatus('Message Sent')
          e.target.reset()
      }, (error) => {
          console.log(error.text);
          setMessageStatus('There has been an error sending the message. Please check the fields.')
      });
      setMessageStatus('')
  };

  return (
    <div className='relative font-ProWebS'>
        <div className='grid mx-10
            md:container md:mx-auto'>
            <div className='mb-5'>
                <h2 className='uppercase font-bold text-3xl
                    text-[var(--colorPrimary)]'>Contactez-nous</h2>
                <p>{messageStatus}</p>
            </div>
            <form ref={form} onSubmit={sendEmail} className="grid gap-6">
                <Input variant='outlined' label="Nom" name='user_name'/>
                <Input variant="outlined" label="Email" name='user_email'/>
                <Textarea label="Message" name='message'/>
                <Button className='button-type-2' type='submit' value='Send' >Envoyer</Button>
            </form>
        </div>
    </div>
  )
}
