import React, { useState } from 'react'
import { useRef } from 'react'

import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

export default function UpdateProfilePage() {
  return (
    <div className='container mx-auto max-w-[750px]'>
        <SignupCard/>
    </div>
  )
}


const SignupCard = () => {
    const emailRef = useRef()
    const passwordRef = useRef();
    const confirmPasswordRef = useRef();
    const { currentUser, updatePswd, updateEm } = useAuth();

    const [error,setError] = useState('')
    const [loading,setLoading] = useState(false)

    const redirect = useNavigate()


    function handleSubmit(e) {
        e.preventDefault()

        if (passwordRef.current.value !== confirmPasswordRef.current.value){
            return setError('Passwords do not match')
        }

        const promises = []
        if(emailRef.current.value !== currentUser.email){
            promises.push(updateEm(emailRef.current.value))
        }
        if(passwordRef.current.value){
            promises.push(updatePswd(passwordRef.current.value))
        }

        Promise.all(promises).then(()=>{
            redirect('/dashboard')
        }).catch(()=>{
            setError('Failed to update account')
        }).finally(()=>{
            setLoading(false)
        })
    }


    return(
        <form onSubmit={handleSubmit} className='grid border-[0.15rem] border-blue-500 rounded-lg py-5 px-3'>
            <h1 className='font-bold text-2xl text-center my-5'>Update Profile</h1>
            <p className='text-center text-red-600'> {error} </p>
            <div className='flex-col'>
                <div className='grid grid-cols-3 py-1'>
                    <div className='col-span-1 grid'>
                        <label className='text-right mx-5'>Email : </label>
                    </div>
                    <div className='col-span-2'>
                        <input type='text' id='email' className='border-[0.05rem] border-black' ref={emailRef} placeholder={currentUser.email}/>
                    </div>
                </div>
                <SignupData Label="Password" InputType="password" Id="password" Ref={passwordRef} Placeholder={'Leave blank to keep the same'}/>
                <SignupData Label="Confirm Password" InputType="password" Id="confirmPassword" Ref={confirmPasswordRef} Placeholder={'Leave blank to keep the same'}/>
            </div>
            
            <div className='text-center'>
                <span className='text-sm'>
                    <Link to="/dashboard" className='rounded-lg px-5 py-2
                my-5 hover:text-white hover:bg-blue-500 duration-75
                font-medium text-blue-500'>Cancel</Link>
                </span>
            </div>
            <button type='submit' disabled={loading}
            className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                my-5 hover:text-white hover:bg-blue-500 duration-75
                font-medium'>Update Profile</button>
        </form>
    )
}

const SignupData = ({Label,InputType,Id,Ref,Required,Placeholder}) => {
    return(
        <div className='grid grid-cols-3 py-1'>
            <div className='col-span-1 grid'>
                <label className='text-right mx-5'>{Label}</label>
            </div>
            <div className='col-span-2'>
                <input type={InputType} id={Id} placeholder={Placeholder} className='border-[0.05rem] border-black' ref={Ref}/>
            </div>
        </div>
    )
}