import { initializeApp } from 'firebase/app'
import { addDoc, collection, getFirestore, doc, getDoc, getDocs, updateDoc, deleteDoc } from "firebase/firestore"; 

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_API_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);

const firestore_db = getFirestore(app)

const COLLECTION_NAME = "ProWebSolutions_DB"

function addClient(data){
    try{
        addDoc(collection(firestore_db,COLLECTION_NAME),data)
        return('Client Successfully Added!')
    }
    catch(e) {
        return(e)
    }
}

export function removeClient(client_id){
    const docRef = doc(firestore_db,COLLECTION_NAME,client_id)
    deleteDoc(docRef).catch(err => console.log(err))    //TODO: create a db where you add the error if needed (so you can keep track of it)
}



// export async function setRefused(document_id){
//     const data = {
//         refused : true,
//         accepted : false
//     }

//     const docRef = doc(firestore_db,COLLECTION_NAME,document_id)
//     updateDoc(docRef,data)
// }


export const collectionRef = collection(firestore_db,COLLECTION_NAME)

export default addClient;