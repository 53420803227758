import React from 'react'
import {Link} from 'react-router-dom'

import {VscGitPullRequestCreate} from 'react-icons/vsc'
import {BsPersonBoundingBox, BsCodeSlash} from 'react-icons/bs'

import HeroImage from '../../resources/Wavy_Bus-06_Single-05-with-shadows-1500x1500-nb.png'
import Image1 from '../../resources/20943839-1500x1500-nb.png'

import AboutImage from '../../resources/3323882.png'
import ContactForm from '../../components/en/ContactForm'
import Header from '../../components/en/Header'

export default function HomePage() {
  return (
    <div>
        <Header/>
        <Hero/>
        <AboutUs/>
        <Features/>
        <CallingUserToContact/>
        <GetInTouch/>
    </div>
  )
}




const Hero = () => {
    const HeroButton = ({To,Text}) => {
        return(
            <Link to={To} className="button-type-1">{Text}</Link>
        )
    } 

    return(
        <div className='heroHolder'>
            <div className=' container mx-auto
                px-10 py-10
                sm:px-20
                md:grid md:grid-cols-2 md:my-auto
                lg:px-40'>
                <div id='text' className='text-white font-ProWebS'>
                    <h1 className='font-bold text-5xl sm:text-6xl
                        xl:text-7xl'>Empowering Your <span id='demotext' >Online</span> Presence</h1>
                    <h2 className='text-3xl py-5
                        xl:text-4xl xl:pr-20'>Elevate Your Business with Powerful Tech & Aesthetic Design</h2>
                    <div className='grid mt-10 mb-5 justify-start'>
                        <HeroButton To={"/en/products"} Text={"Discover"} />
                    </div>
                </div>
                <div id='photo' className=''>
                    <img src={HeroImage} alt='Pro Web Solutions website type' className=''></img>
                </div>
            </div>
        </div>
    )
}

const AboutUs = () => {
    const AboutButton = ({To,Text}) => {
        return(
            <Link to={To} className="button-type-1">{Text}</Link>
        )
    }
    const InfoCard = ({Photo,Title,Text}) => {
        return(
            <div className='font-ProWebS container mx-auto lg:my-32'>
                <div className='grid mx-10 py-10
                    lg:grid-cols-2'>
                    <div className='my-5'>
                        <img src={Photo} alt='about ProWebSolutions' className='w-[350px] h-[350px] mx-auto
                            md:w-[450px] md:h-[450px]'></img>
                    </div>
                    <div className='bg-[var(--colorSecondary-75)] p-5 text-white'>
                        <h2 className='text-[var(--colorPrimary)] font-bold text-3xl'>{Title}</h2>
                        <p className='text-[var(--colorBlack)] font-medium mt-10 text-lg'>{Text}</p>
                        <div className='my-10'>
                            <AboutButton To={'/en/about'} Text={'Read More'}/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    return(
        <div className='mb-20'>
            {/* <InfoCard Photo={AboutImage} Title={"Your trusted partner in creating exceptional web solutions."} Text={"Based in Belgium, our company is dedicated to delivering top-notch websites to businesses of all sizes. We pride ourselves on utilizing cutting-edge technologies to offer the best possible solutions to our clients.But our commitment doesn't stop there. We understand the importance of scalability in today's fast-paced digital world. That's why we work with cloud-based solutions to make your web presence easily scalable. Whether your business experiences rapid growth or sudden surges in traffic, our cloud-based approach ensures that your website performs optimally, maintaining a seamless user experience for your audience."} /> */}
            <div className='font-ProWebS container mx-auto lg:my-32'>
                <div className='grid mx-10 py-10
                    lg:grid-cols-2'>
                    <div className='my-5'>
                        <img src={AboutImage} alt='about ProWebSolutions' className='w-[350px] h-[350px] mx-auto
                            md:w-[450px] md:h-[450px]'></img>
                    </div>
                    <div className='p-5 text-white'>
                        <h2 className='text-[var(--colorPrimary)] font-bold text-3xl'>Your trusted partner in creating exceptional web solutions.</h2>
                        <p className='text-[var(--colorBlack)] font-medium mt-10 text-lg'>
                            Based in Belgium, our company is dedicated to delivering top-notch websites to businesses of all sizes. 
                            We pride ourselves on utilizing cutting-edge technologies to offer the best possible solutions to our clients.
                            <br/><br/>
                            But our commitment doesn't stop there. We understand the importance of scalability in today's fast-paced digital world. 
                            That's why we work with cloud-based solutions to make your web presence easily scalable. Whether your business experiences rapid growth or sudden surges in traffic,
                            our cloud-based approach ensures that your website performs optimally, maintaining a seamless user experience for your audience.
                        </p>
                        <div className='my-10'>
                            <AboutButton To={'/en/about'} Text={'Read More'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


const CallingUserToContact = () => {
    return(
        <div className='font-ProWebS container mx-auto my-5'>
            <div className='grid grid-flow-row px-10 space-y-5
                md:grid-rows-1 md:grid-cols-2 md:space-x-4'>
                <div className='px-5 py-4 bg-primary
                    md:px-10 md:py-6
                    lg:px-20'>
                    <h1 className='font-bold text-5xl text-white
                        md:text-6xl
                        lg:text-7xl
                        xl:text-8xl'>
                        Make your vision reality.
                    </h1>
                </div>
                <div className='grid
                    md:space-y-4'>
                    <div className='self-end grid px-10 py-5 bg-[var(--colorBlack-15)]
                        lg:mr-10 lg:mt-32'>
                        <div>
                            <h2 className='font-medium text-3xl
                                md:text-4xl
                                lg:text-5xl'>Find your web solutions today</h2>
                        </div>
                        <div className='text-white my-10'>
                            <Link to='/en/products' className="button-type-1">See More</Link>
                        </div>
                    </div>
                    <div className='grid'>
                        <img src={Image1} alt='Pro Web Solutions contact us' className='lg:w-[70%] '></img>
                    </div>
                </div>
            </div>
            <div className='self-end'>
                
            </div>
        </div>
    )
}


const Features = () => {
    const FeatureCard = ({Icon,Title,Text}) => {
        return(
            <div className=' grid py-5 px-5
             bg-[var(--colorSecondary-75)] 
             text-white
             w-[20rem]
             md:w-[14.5rem]
             xl:w-[400px]
             2xl:w-[500px]'>
                <div className='text-4xl justify-self-center
                    xl:text-6xl my-auto'>
                    {<Icon/>}
                </div>
                <div className='text-center text-[var(--colorPrimary)]'>
                    <h2 className='font-bold text-2xl
                        2xl:text-4xl'>{Title}</h2>
                </div>
                <div className='text-center'>
                    <p className='text-lg m-2 h-[150px]
                        md:h-[250px]
                        xl:h-[150px]
                        2xl:text-xl 2xl:mx-10'>
                        {Text}
                    </p>
                </div>

            </div>
        )
    }

    return(
        <div className='font-ProWebS grid
            bg-secondary 
            py-10'>
            <div className='text-white text-center mx-5'>
                <h3 className='text-2xl py-3 
                    md:text-3xl'>Features</h3>
                <div className='border-b-[0.35rem] border-white mx-20 mb-5' />
                <h2 className='text-4xl font-bold mb-10
                    lg:text-5xl'>Using design to improve our products</h2>
            </div>
            <div className='grid justify-center
                md:grid-flow-col'>
                <FeatureCard Icon={VscGitPullRequestCreate} Title={"Strategy"} Text={"Customer-centric strategy fuels our process, ensuring the final product perfectly aligns with your unique goals."}/>
                <FeatureCard Icon={BsPersonBoundingBox} Title={"Concept"} Text={"With a well-defined business concept, our web development projects are laser-focused, delivering products that perfectly align with your objectives."}/>
                <FeatureCard Icon={BsCodeSlash} Title={"Development"} Text={"As we enter development, your concept blossoms into a polished product, meticulously fine-tuned and tested to deliver an exceptional user journey."}/>
            </div>
        </div>
    )
}


const GetInTouch = () => {
    const GetInTouchCard = () => {
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto
                    lg:w-96'>
                    <h3 className='uppercase font-bold text-3xl
                        text-[var(--colorPrimary)]'>Get In touch</h3>
                    <h5 className='font-medium italic my-5'>Our focus is on reliability and cost-effectiveness, ensuring your comfort throughout the journey.</h5>
                    <p className='py-2'>Steered by our values, we stand on the pillars of reliability and cost-effectiveness. Above all, your satisfaction remains our primary concern. Quick responses reinforce trust through transparent interactions.</p>
                    <div id='social-media-icons'>
                        
                    </div>
                </div>
            </div>
        )
    }

    const CallUs = () =>{
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto'>
                    <h3 className='uppercase text-xl font-bold mb-2
                        text-[var(--colorPrimary)]'>CALL US </h3>
                    <h5>1 (234) 567-891</h5>
                </div>
            </div>
        )
    }

    const TopService = () => {
        return(
            <div className=''>
                <div className='grid mx-10
                    md:container md:mx-auto'>
                    <h3 className='uppercase text-xl font-bold mb-2
                        text-[var(--colorPrimary)]'>OUR TOP SERVICES</h3>
                    <ul className='my-5'>
                        <li>✔ <span className='font-medium'>24h</span> response time</li>
                        <li>✔ <span className='text-green-600 font-medium'>Free</span> online quotation</li>
                        <li>✔ <span className='font-medium'>Transparency</span> </li>
                    </ul>
                </div>
            </div>
        )
    }

    return(
        <div className='font-ProWebS flex flex-col gap-5 my-20
            lg:container lg:grid lg:grid-flow-col lg:mx-auto'>
            <GetInTouchCard/>
            <div className='flex flex-col gap-5'>
                {/* <CallUs/> */}
                <TopService/>
            </div>
            <ContactForm/>
        </div>
    )
}

