import React, { useState } from 'react'
import { useRef } from 'react'

import { useAuth } from '../../context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'

export default function Login() {
  return (
    <div className='container mx-auto w-[400px]'>
        <LoginCard/>
    </div>
  )
}


const LoginCard = () => {
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();

    const [error,setError] = useState('')
    const [loading,setLoading] = useState(false)

    const redirect = useNavigate()

    async function handleSubmit(e) {
        e.preventDefault()

        try {
            setError('')
            setLoading(true)
            await login(emailRef.current.value, passwordRef.current.value)
            redirect('/dashboard')
        }   
        catch(e){
            setError("Failed to sign in")
        }
        setLoading(false)
    }


    return(
        <form onSubmit={handleSubmit} className='grid border-[0.15rem] border-blue-500 rounded-lg py-5 px-3'>
            <h1 className='font-bold text-2xl text-center my-5'>Log In</h1>
            <p className='text-center text-red-600'> {error} </p>
            <LoginData Label="Email" InputType="email" Id="email" Ref={emailRef}/>
            <LoginData Label="Password" InputType="password" Id="password" Ref={passwordRef}/>
            <button type='submit' disabled={loading}
            className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                my-5 hover:text-white hover:bg-blue-500 duration-75
                font-medium'>Log In</button>
        </form>
    )
}

const LoginData = ({Label,InputType,Id,Ref}) => {
    return(
        <div className='grid grid-cols-3 py-1'>
            <div className='col-span-1 grid'>
                <label className='text-right mx-5'>{Label}</label>
            </div>
            <div className='col-span-2'>
                <input required type={InputType} id={Id} className='border-[0.05rem] border-black' ref={Ref}/>
            </div>
        </div>
    )
}