import { Input, Textarea } from '@material-tailwind/react'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import Image from '../../../../resources/time-management.jpg'
import addAppointment from './firebase_appointmentSystem_Solstice'
import { serverTimestamp } from 'firebase/firestore'

import ImageSolstice from '../../../../resources/house-neon.jpg'
import ImageBG from '../../../../resources/dancing-people-club.jpg'
import ImageBG2 from '../../../../resources/young-woman-silver-cloth-with-upping-hand-pinkness.jpg'
import PWSLogo1 from '../../../../resources/logos/Pro-Web-Solutions-300x1000-1.png'
import PWSLogo2 from '../../../../resources/logos/Pro-Web-Solutions-logo-1000x300-no-bg.png'

export default function AppointmentClient() {
  return (
    <div className='flex-col lg:flex lg:flex-row'>
        <div className='hidden lg:flex bg-white h-screen '>    
            <a href='https://www.prowebsolutions.online/' className='my-auto'><img src={PWSLogo1} alt='...' className='w-[150px] '/></a>
        </div>
        <div className='flex m-0 p-0 bg-white lg:hidden'>    
            <img src={PWSLogo2} alt='...' className='w-[300px] mt-10 mx-auto' />
        </div>
        <div className='relative rounded-md my-10 container mx-auto lg:mr-auto lg:ml-36 2xl:mx-auto '>
            <img src={ImageBG} alt='...' className='absolute z-[-10] top-0 hidden lg:block' />    
            <img src={ImageBG2} alt='...' className='absolute z-[-10] top-0 lg:hidden' />    
            <div className=' grid lg:grid-cols-2 py-10 px-10 xl:my-16 2xl:my-32 backdrop-blur-sm bg-gray-300/10'>
                <div className='max-w-[750px] my-auto mx-auto'>
                    <img src={ImageSolstice} alt='time management icon'/>
                </div>
                <AppointmentClientCard/>
            </div>
        </div>

    </div>
  )
}



const AppointmentClientCard = () => {

    const [email,setEmail] = useState("");
    // const [appointment_date,setAppointment_date] = useState("");
    const [appointment_hour,setAppointment_hour] = useState("");
    const [persons_number,setPersons_number] = useState("");
    const [comments,setComments] = useState("");
    const [added_time,setAdded_time] = useState("");

    const [appointment_state,setAppointment_state] = useState("");



    async function clearForm(waitingSeconds,e,setState){
    
        function timeout(delay){
            return new Promise(res => setTimeout(res,delay));
        }
        await timeout(waitingSeconds*1000);
        e.target.reset()
        setState("")
    }

    const addAppointmentToFirestore = (e) => {
        setAdded_time(serverTimestamp());   //using firebase server timestamp

        const data = {
            email : email,
            // appointment_date : appointment_date,
            appointment_hour : appointment_hour,
            persons_number : persons_number,
            comments : comments,
            added_time : added_time,
            appointment_verified : false,
            accepted : false,
            refused : false
        }

        let response = addAppointment(data);
        setAppointment_state(response)
        clearForm(3,e,setAppointment_state)
    }

    return(
        <div className='md:max-w-[550px] mx-auto my-auto '>
            <div className=' py-5 px-7'>
                <h1 className='text-[var(--colorPrimary)] text-3xl font-bold pb-5'>Reserver une table</h1>
                <form className='grid gap-5' onSubmit={addAppointmentToFirestore}>
                    <p className='text-sm text-gray-600'>{appointment_state}</p>
                    <div className='w-72 '>
                        <Input type='email' required label='Email' id='user_email' onChange={(e)=> {setEmail(e.target.value)}} className='text-white bg-transparent' color='white'/>
                    </div>
                    {/* <div className='w-48'>
                        <Input type='date' min={new Date().toISOString().split("T")[0]} required label='Appointment Date' id='user_appointment_date' onChange={(e)=> {setAppointment_date(e.target.value)}} className='text-center'/>
                    </div> */}
                    <div className='w-48'>
                        <Input type='time' required label='Heure' id='user_appointment_hour' onChange={(e)=> {setAppointment_hour(e.target.value)}} className='text-center text-white' color='white'/>
                    </div>
                    <div className='w-48'>
                        <Input type='number' required label='Nombre de personnes' id='user_persons_number' onChange={(e)=> {setPersons_number(e.target.value)}} className='text-center text-white' color='white'/>
                    </div>
                    
                    <div className='mr-20 w-full'>
                        <Textarea label='Commentaires' id='user_comments' onChange={(e)=> {setComments(e.target.value)}} className='bg-white my-1'/>
                    </div>
                    <div>
                        <button className='button-type-2 text-white py-3 px-5' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}