import React from 'react'

import AppointmentClient from './AppointmentClient'
import { Link } from 'react-router-dom'

export default function SolsticeRendezvousPage() {
  return (
    <div>
      <nav className='grid grid-flow-col justify-start gap-5 mx-10'>
        <Link to='/examples/solstice-rendezvous-admin' className='px-5 py-2 bg-[var(--colorPrimary)] text-lg font-medium hover:text-[var(--colorPrimary)] hover:bg-white'>Admin View</Link>
        <Link to='/examples/solstice-rendezvous' className='px-5 py-2 bg-[var(--colorPrimary)] text-lg font-medium hover:text-[var(--colorPrimary)] hover:bg-white'>Client View</Link>
      </nav>
      <AppointmentClient/>
    </div>
  )
}


