import React from 'react'
import { Link } from 'react-router-dom'
import AppointmentAdmin from './AppointmentAdmin'

export default function SolsticeAdminPage() {
  return (
    <div>
      <nav className='grid grid-flow-col justify-start gap-5 mx-10'>
        <Link to='/examples/solstice-rendezvous-admin' className='px-5 py-2 bg-[var(--colorPrimary)] text-lg font-medium hover:text-[var(--colorPrimary)] hover:bg-white'>Admin View</Link>
        <Link to='/examples/solstice-rendezvous' className='px-5 py-2 bg-[var(--colorPrimary)] text-lg font-medium hover:text-[var(--colorPrimary)] hover:bg-white'>Client View</Link>
      </nav>
      
      <div>
        <AppointmentAdmin/>
      </div>

    </div>
  )
}
