import React, { useEffect, useState } from 'react'

import Logo from '../../resources/logos/Pro-Web-Solutions-logo-with-text-500x500-white-bg.png'
import { Link } from 'react-router-dom'

import FrFlag from '../../resources/fr-flag.png'


import { GiHamburgerMenu } from 'react-icons/gi'
import { FaQuestionCircle,FaHome  } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { LuBoxes } from "react-icons/lu";



export default function Header() {  
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (menuOpen) {
            document.body.classList.add("overflow-hidden");
        } else {
            document.body.classList.remove("overflow-hidden");
        }

        return () => {
            document.body.classList.remove("overflow-hidden");
        };
    }, [menuOpen]);

    const toggleMenu = (e) => {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    };

    return (
        <header className='relative md:sticky md:top-0 md:z-10'>
            
            <div className={`transition-transform duration-500 ${menuOpen ? 'transform translate-x-[-300px] md:translate-x-[0px]' : ''}`}>
                <div className='bg-white flex justify-between'>
                    
                    <div className='ml-5'>
                        <a href={'/#/en/home'}>
                            <img src={Logo} alt='Pro Web Solutions Home' className='w-[125px]' />
                        </a>
                    </div>

                    <div className={`mx-5 my-auto grid grid-flow-col gap-10 items-center transition-transform duration-500 ${menuOpen ? 'transform md:translate-x-[-300px]' : ''}`}>
                        <Link to={'/fr/home'} className='md:mr-20'>
                            <img src={FrFlag} className='mx-auto rounded-full h-[50px] w-[50px]' alt='' />
                        </Link>
                        <div className='grid text-4xl justify-center' onClick={toggleMenu}>
                            <GiHamburgerMenu />
                        </div>
                    </div>

                </div>
            </div>

            <div className={`fixed top-0 right-0 bg-gray-500/60 backdrop-blur-sm w-[300px] h-screen flex flex-col pt-52
                            transform transition-transform duration-500 ${menuOpen ? 'translate-x-0' : 'translate-x-full'}`}>
                <Link to={'/en/home'} className='text-xl text-left text-white px-2 py-3 border-b-[0.10rem] border-[var(--colorPrimary)] hover:bg-[var(--colorPrimary)] transition-all duration-75 font-bold uppercase flex'><FaHome className='text-2xl mr-5'/> Home</Link>
                <Link to={'/en/about'} className='text-xl text-left text-white px-2 py-3 border-b-[0.10rem] border-[var(--colorPrimary)] hover:bg-[var(--colorPrimary)] transition-all duration-75 font-bold uppercase flex'><FaQuestionCircle className='text-2xl mr-5'/> About Us</Link>
                <Link to={'/en/contact'} className='text-xl text-left text-white px-2 py-3 border-b-[0.10rem] border-[var(--colorPrimary)] hover:bg-[var(--colorPrimary)] transition-all duration-75 font-bold uppercase flex'><IoIosMail className='text-2xl mr-5'/> Contact</Link>
                <Link to={'/en/products'} className='text-xl text-left text-white px-2 py-3 border-b-[0.10rem] border-[var(--colorPrimary)] hover:bg-[var(--colorPrimary)] transition-all duration-75 font-bold uppercase flex'><LuBoxes className='text-2xl mr-5'/> Products</Link>
            </div>
        </header>
    );
}



