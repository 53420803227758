import { Link } from "react-router-dom"

export default function DashboardPage(){
    
    return(
        <div className="h-screen">
            <div className="grid pt-5">
              <h1 className="font-bold text-3xl text-center"> Dashboard Page </h1>
              <div className="container mx-auto max-w-[750px]">
                  <DashboardCard/>
              </div>
            </div>
        </div>
    )
}


const DashboardCard = () => {

    return(
        <div className="mt-20 mb-10 border-[0.15rem] border-blue-500 rounded-lg py-5 px-3">
            <div id="card" className="grid">
                <h1 className="text-2xl text-center text-blue-700"> User Systems </h1>
                
                
                <div className="grid grid-cols-2 ">
                  <Link to={'/appointment-system/admin-view'} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>Appointments [Admin View]</Link>
                  <Link to={'/appointment-system/client-view'} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>Appointments [Client View]</Link>
                  <Link to={'/examples'} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>Examples</Link>
                  <Link to={'/management-system'} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>Management System</Link>
                  <Link to={''} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>System 4</Link>
                  <Link to={''} disabled={false}
                  className='border-[0.15rem] border-blue-500 rounded-lg mx-auto px-5 py-2
                  my-5 hover:text-white hover:bg-blue-500 duration-75
                  font-medium'>System 5</Link>

                </div>
            </div>
        </div>
    )
}