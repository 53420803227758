import React from 'react'
import { Link } from 'react-router-dom'

import ImageSolstice from '../../../resources/house-neon.jpg'
import ImageDefault from '../../../resources/27002.jpg'

export default function ExamplesPage() {
  return (
    <div className='font-ProWebS container mx-auto py-10 my-10 rounded-lg bg-[var(--colorSecondaryBright-md)]'>
        <h2 className='text-3xl font-bold py-3 text-center'>Examples</h2>
        <ul className='grid px-10 gap-5'>
            <li className='flex gap-5'>
                <img src={ImageSolstice} alt='example_image' className='w-[150px]'/>
                <Link to={'/examples/solstice-rendezvous'} className='my-auto px-3 py-5 bg-[var(--colorPrimary)]
                text-white hover:bg-white hover:text-[var(--colorPrimary)] transition-all duration-100 font-medim'>Rendez-Vous Solstice Rooftop</Link>
            </li>
            <li className='flex gap-5'>
                <img src={ImageDefault} alt='example_image' className='w-[150px]'/>
                <Link to={''} className='my-auto px-3 py-5 bg-[var(--colorPrimary)]
                text-white hover:bg-white hover:text-[var(--colorPrimary)] transition-all duration-100 font-medim'>Title</Link>
            </li>
        </ul>
    </div>
  )
}
