import React, { useState } from 'react'

import JSONData from '../../data/aboutUs.json'


import Image_icon_1 from '../../resources/icon-coding-pink.png'
import Image_icon_2 from '../../resources/illustration-pink.png'
import Image_icon_3 from '../../resources/image-editing-pink.png'
import Image_icon_4 from '../../resources/strategy-pink.png'

import Icon_dev from '../../resources/icon-coding.png'
import FrHeader from '../../components/fr/FrHeader'

export default function FrAboutUsPage() {  

  return (
    <div className='font-ProWebS '>
      <FrHeader/>
      <div className=' bg-[var(--colorBlack)]'>
        <div className='grid justify-center py-10'>
          <AboutBelt/>
        </div>
        <div>
          <AboutCard/>
        </div>
      </div>
    </div>
  )
}

const AboutBelt = () => {
  const Item = ({Image,Text}) => {
    return(
      <div className='grid gap-3 py-3'>
        <div className='mx-auto w-[100px]'>
          <img src={Image} alt='...'></img>
        </div>
        <div className='mx-auto'>
          <p className='text-xl text-white '>{Text}</p>
        </div>
      </div>
    )
  }
  return(
    <div className='grid md:flex gap-10 md:gap-32'>

      <Item Image={Image_icon_4}Text={"Stratégie"} />
      <Item Image={Image_icon_3}Text={"Conception"} />
      <Item Image={Image_icon_2}Text={"Montage"} />
      <Item Image={Image_icon_1} Text={"Développement"} />
      
    </div>
  )
}

const AboutCard = () => {
  return(

    <div className='bg-[var(--colorPrimary)] container mx-auto px-5 py-10'>
      <h1 className='text-4xl text-white text-center font-medium tracking-wider py-5
        md:text-5xl md:py-10'>À propos de <br className='md:hidden'/>Pro Web Solutions</h1>
      <div className='text-white flex-col text-center
        md:grid md:grid-cols-2 md:text-left'>
        <h3 className='text-3xl font-bold tracking-wider py-10 px-5
          md:text-5xl lg:text-6xl md:py-0 '>
          Nous développons des <span className='text-[var(--colorSecondary)]'>Solutions</span> numériques, des produits et des services.
        </h3>
        <p className='text-lg
          md:text-2xl overflow-hidden'>
          Nous exploitons la puissance de <span className='font-bold'>Firebase</span> et <span className='font-bold'>React</span> pour construire des sites web dynamiques, sécurisés et riches en fonctionnalités. Firebase, en tant que base de données principale, garantit que vos données sont sécurisées et accessibles en tout temps, offrant une tranquillité d'esprit à vous et à vos clients. Pendant ce temps, React, une puissante bibliothèque JavaScript, nous permet de créer des interfaces utilisateur hautement interactives et réactives, offrant une expérience utilisateur exceptionnelle.
          <br/><span className=' text-center'>..........................................................</span>
        </p>
      </div>
    </div>
)
}

{/* <div className='bg-[var(--colorSecondaryBright-sm)] py-10 font-ProWebS '>
      <div className='bg-white container mx-auto'>
        <h1 className='text-4xl text-[var(--colorPrimary)] px-2 py-5 text-center font-bold
          md:text-left pl-10'>About Us</h1>
        <div className='grid gap-10'>
          <AboutUsCard1 Text={JSONData.data.text[0].paragraph_1} Image={Image_3} />
          <AboutUsCard2 Text={JSONData.data.text[1].paragraph_2} Image={Image_2}/>
          <AboutUsCard1 Text={JSONData.data.text[2].paragraph_3}/>
        </div>
      </div>
    </div> */}

// TODO: for firebase, create a nice photo using their logo to apply here


const AboutUsCard1 = ({Text,Image}) => {
  return(
    <div className='grid gap-5 lg:grid-cols-2'>
      <div className='mx-10 my-5 lg:m-auto'>
          <img src={Image} alt='' className='lg:max-w-xl rounded-full'></img>
      </div>
      <div className='grid gap-5 px-5 py-10'>
        {Text.map((value,key) => {
          return(
            <p key={key} className='text-xl text-[var(--colorBlack)] font-medium my-auto' >{value}</p>
          )
        })}
      </div>
    </div>
  )
}

const AboutUsCard2 = ({Text,Image}) => {
  return(
    <div className='grid gap-5 lg:grid-cols-2'>
      <div className='lg:hidden mx-10 my-5 lg:m-auto'>
          <img src={Image} alt='' className='lg:max-w-xl rounded-full'></img>
      </div>
      <div className='grid gap-5 px-5 py-10'>
        {Text.map((value,key) => {
          return(
            <p key={key} className='text-xl text-[var(--colorBlack)] font-medium my-auto' >{value}</p>
          )
        })}
      </div>
      <div className='hidden lg:block mx-10 my-5 lg:m-auto'>
          <img src={Image} alt='' className='lg:max-w-xl rounded-full'></img>
      </div>
    </div>
  )
}