import React, { useRef, useState } from 'react'
import emailjs from '@emailjs/browser';

import { Button, Input,Textarea} from "@material-tailwind/react";
import Header from '../../components/en/Header';
import ProductCard from '../../components/global/ProductCard';

import { Link } from 'react-router-dom';

import Image_1 from '../../resources/background-testing-2.png';

export default function ContactServicePage({Language,PackageData,WantedPackage}) {

    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

    const languageText2 = {
        'fr' : {
            'package_presentation' : {
                'Title' : 'VOTRE SERVICE',
                'button_prefix' : 'Devenir '
            },
            'special_form' : {
                'Title1' : "Contactez-nous",
                'Title2' : "N'hésitez pas de nous envoyer déjà vos questions avant qu'on vous contacte !",
                'Labels' : ["Nom","Email","Service"],
                "ButtonName" : "Envoyer"
            },
        },
        'en' : {
            'package_presentation' : {
                'Title' : 'YOUR PACKAGE',
                'button_prefix' : 'Go '
            },
            'special_form' : {
                'Title1' : "Let's Talk",
                'Title2' : "Before we reach out for you, let us know if you have any questions !",
                'Labels' : ["Name","Email","Package"],
                "ButtonName" : "Submit"
            },
        }
    }

    let wantedPackage;
    let otherPackages = []

    for(let i = 0; i < PackageData.services.length;i++){
        let name = PackageData.services[i].name
        if(WantedPackage === name){
            wantedPackage = PackageData.services[i]
        }
        else{
            otherPackages.push(PackageData.services[i])
        }
    }

    
    const packagesProposalButton = (PackageName,To) => {
        return(
            <Link className='button-buy text-center shadow-lg' to={To}>Go {PackageName}</Link>
        )
    }

    const ProposalButtonHolder = (Packages) => {

        let otherButtons = []

        for(let i = 0; i < Packages.length; i++){
            
            let name = Packages[i].name;
            let path = `/${Language}/${name}package`
            otherButtons.push(packagesProposalButton(name,path.toLowerCase()))
        }

        return(
            <div className='grid grid-cols-2 py-10 gap-5 '>
                {otherButtons}
            </div>
        )
    }

    return (
        <div className=''>
            <Header/>
            <div className='relative'>
                <div className='flex flex-col gap-10 lg:flex-row'>
                    <div className='mx-auto py-5 px-3 lg:mx-0 lg:ml-32 xl:ml-80'>
                        <h2 className='uppercase font-bold text-3xl text-[var(--colorSecondary)]
                            py-3'>Your Package</h2>
                        <div className='grid'>
                            <ProductCard DetailedServicesNames={PackageData.detailed_services_names} Service={wantedPackage} Hightlight={false} Href={'/#/en/contact'} ButtonName={"GET NOW"} ButtonState={false} SpecialOffer={true} SpecialOfferText={"SPECIAL OFFER"} />
                        </div>
                       {ProposalButtonHolder(otherPackages)}
                    </div>
                    <div className=' mx-auto my-auto p-5 w-96 md:w-[32rem] lg:mx-0 xl:ml-24 '>
                        <SpecialContactForm Title1={languageText2[Language].special_form.Title1} Title2={languageText2[Language].special_form.Title2} Labels={languageText2[Language].special_form.Labels} ButtonName={languageText2[Language].special_form.ButtonName} WantedPackage={WantedPackage} />
                        
                    </div>
                </div>
                <div className='invisible md:visible absolute z-[-10] top-0 right-52 '>
                    <img src={Image_1} alt='ProWebSolutions background '/>
                </div>
            </div>
            
        </div>
      )
    }


function SpecialContactForm({Title1,Title2,Labels,ButtonName,WantedPackage}) {

  const [messageStatus,setMessageStatus] = useState('')

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    var SERVICE_ID = process.env.REACT_APP_CONTACT_SERVICE_ID
    var TEMPLATE_ID = process.env.REACT_APP_CONTACT_TEMPLATE_PRODUCT_ID
    var PUBLIC_API_KEY = process.env.REACT_APP_CONTACT_PUBLIC_API_KEY


    emailjs.sendForm(
        SERVICE_ID,
        TEMPLATE_ID,
        form.current,
        PUBLIC_API_KEY
      )
      .then((result) => {
          console.log(result.text);

          setMessageStatus('Message Sent')
          e.target.reset()
      }, (error) => {
          console.log(error.text);
          setMessageStatus('There has been an error sending the message. Please check the fields.')
      });
      setMessageStatus('')
  };

  return (
    <div className='relative font-ProWebS'>
        <div className='grid mx-10
            md:container md:mx-auto'>
            <div className='mb-5'>
                <h2 className='uppercase font-bold text-3xl
                    text-[var(--colorSecondary)] py-3'>{Title1}</h2>
                <p className='text-lg text-[var(--colorBlack)] font-medium'>{Title2}</p>
                <p>{messageStatus}</p>
            </div>
            <form ref={form} onSubmit={sendEmail} className="grid gap-6">
                <Input variant='outlined' label={Labels[0]} name='user_name'/>
                <Input variant="outlined" label={Labels[1]} name='user_email'/>
                <Input variant='outlined' label={Labels[2]} value={WantedPackage +' Package'} contentEditable='false' name='user_package' readOnly={true}/>
                <Textarea label="Message" name='message'/>
                <Button className='button-type-2' type='submit' value='Send' >{ButtonName}</Button>
            </form>
        </div>
    </div>
  )
}
