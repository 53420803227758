
import React from 'react'

import {Link} from 'react-router-dom'

export default function Footer() {
    const Creator = () => {
        return(
            <span className='text-gray-400 text-center my-5'>© Pro Web Solutions 2023 </span>
        )
    }
  
    return (
    <footer className='grid bg-[var(--colorBlack)] pt-10'>
        <div className='flex flex-row mx-auto px-5'>
            <div className='grid md:grid-flow-col lg:gap-40'>
                <Images />
                <div className='my-5'>
                    <Link to='/terms&services' className='text-white underline hover:text-blue-700'>Terms & Services</Link>
                </div>
                
            </div>
            
        </div>
        <Creator/>
        
    </footer>
  )
}


const Images = () => {
    return(
        <div className='grid grid-flow-row text-white my-5 gap-3'>
            <h4 className='text-xl font-medium underline'>Images</h4>
            <a href="http://www.freepik.com" className='text-sm text-blue-400 hover:underline hover:text-blue-700'>Designed by vectorjuice / Freepik</a>
            {/* <Link to={'/images/licences'} className='text-sm text-blue-400 hover:underline hover:text-blue-700'>Licenses</Link> */}
        </div>
    )
}


