import React from 'react'


import ProductsData from '../../data/products.json'
import FrHeader from '../../components/fr/FrHeader'


import ProductCard from '../../components/global/ProductCard'

export default function FrProductsPage() {

  window.scrollTo({ top: 0, left: 0, behavior: 'instant' });

  const ProductCardHolder = () => {
    return(
      
      <div className='mx-10 my-10 flex flex-col gap-10
        lg:flex-row lg:justify-center'>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['fr'].detailed_services_names} Service={ProductsData['fr'].services[0]} Hightlight={false} Href={'/#/fr/basicpackage'} ButtonName={"OBTENEZ MAINTENANT"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"PRIX PROMOTIONNEL"}/>
        </div>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['fr'].detailed_services_names} Service={ProductsData['fr'].services[1]} Hightlight={true} Href={'/#/fr/propackage'} ButtonName={"OBTENEZ MAINTENANT"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"PRIX PROMOTIONNEL"}/>
        </div>
        <div className='mx-auto lg:mx-0'>
          <ProductCard DetailedServicesNames={ProductsData['fr'].detailed_services_names} Service={ProductsData['fr'].services[2]} Hightlight={false} Href={'/#/fr/businesspackage'} ButtonName={"OBTENEZ MAINTENANT"} ButtonState={true} SpecialOffer={true} SpecialOfferText={"PRIX PROMOTIONNEL"}/>
        </div>
      </div>
    )
  }


  return (
    <div className=''>
      <FrHeader/>
      <div className='pt-10 pb-5 bg-[var(--colorBlack-15)] overflow-hidden'>
        <div className='px-5 py-3'>
          <h1 className='font-ProWebS text-3xl font-bold mb-3
            sm:text-4xl
            md:text-5xl'> <span className='text-[var(--colorPrimary)]'>Commencez maintenant</span> et ne regardez pas en arrière </h1>
          <h3 className='font-ProWebS text-lg text-[var(--colorBlackBright-sm)]'>
          Une personnalisation améliorée de votre produit peut être réalisée ayant comme base les offres suivantes.
            </h3>
        </div>
        <ProductCardHolder  />
        
        <div className='text-sm mx-20'>
          <span>*Les prix affichés peuvent être sujets à modification à la fin de la période de vente..</span>
        </div>
      </div>

      {/* <AdditionalServices /> */}
      

    </div>
  )
}

const AdditionalServices = () => {
  return(
    <div className='md:container md:mx-auto my-10 '>
        <div className='bg-[var(--colorPrimary)] py-3 px-2 flex flex-col'>
          <h1 className='text-white text-3xl font-bold'> Services Supplémentaires </h1>
          <p className='text-xs text-white'>Ces services sont des options complémentaires pour les offres de base.</p>
        </div>
        <div className='flex flex-col gap-5
              md:grid md:grid-flow-col '>
          <HostingAddon/>
          <SEOAddon/>
          <DesignAddon/>
        </div>
      </div>
  )
}


const HostingAddon = () => {
  const HostCard = ({Name,Price,Valute,BillingType}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-md)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}/month</span></h1>
        </div>
        <div>
          <p className='text-xs'>Billed {BillingType}</p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>Hosting</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <HostCard Name={"Solo Pack"} Price={''} Valute={'€'} BillingType={'monthly'} />
          <HostCard Name={'Three Friends Pack'} Price={''} Valute={'€'} BillingType={'every 3 months'} />
          <HostCard Name={'Trust Pack'} Price={''} Valute={'€'} BillingType={'anually'} />
        </div>
      </div>
    </div>
  )
}

const SEOAddon = () => {
  const SEOCard = ({Name,Price,Valute,Description}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-sm)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}</span></h1>
        </div>
        <div>
          <p className='text-sm'>
            {Description}
          </p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>SEO</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <SEOCard Name={"Basic"} Price={''} Valute={'€'} Description={"Default SEO generated for your page."} />
          <SEOCard Name={'Starter'} Price={''} Valute={'€'} Description={"Researched SEO, optimised content and metadata."}/>
          <SEOCard Name={'Best experience'} Price={''} Valute={'€'} Description={"Starter + deep research, monthly testing and more."} />
        </div>
      </div>
    </div>
  )
}


const DesignAddon = () => {
  const DesignCard = ({Name,Price,Valute,PerPage,Description}) => {
    return(
      <div className='text-[var(--colorBlack)] text-center 
        mx-auto px-5 py-3
        w-[250px] 
        bg-[var(--colorSecondaryBright-md)]'>
        <div className='grid'>
          <h3 className='text-xl border-b border-[var(--colorBlack)]'>{Name}</h3>
          <h1 className='font-bold text-2xl'>{Price}<span className='text-sm font-normal'>{Valute}{PerPage?<span className='text-sm'>/page</span>:<></>}</span></h1>
        </div>
        <div>
          <p className='text-sm'>
            {Description}
          </p>
        </div>
      </div>
    )
  }

  return(
    <div>
      <div className='text-center'>
        <h1 className='text-xl underline'>Design</h1>
      </div>
      <div>
        <div className='flex flex-col gap-5'>
          <DesignCard Name={"Responsive"} PerPage={true} Price={''} Valute={'€'} Description={""} />
          <DesignCard Name={'Animated'} PerPage={true} Price={''} Valute={'€'} Description={""}/>
          <DesignCard Name={'Custom Design'} PerPage={true} Price={''} Valute={'€'} Description={""} />
        </div>
      </div>
    </div>
  )
}