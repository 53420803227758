import React, { useState } from 'react';
import { BrowserRouter, Routes, Route, HashRouter, Link } from 'react-router-dom';

import Footer from './components/global/Footer';


import HomePage from './pages/en/HomePage';
import AboutUsPage from './pages/en/AboutUsPage';
import ContactPage from './pages/en/ContactPage';
import ProductsPage from './pages/en/ProductsPage';
import TermsAndServicesPage from './pages/en/TermsAndServicesPage';

import FrHomePage from './pages/fr/FrHomePage';
import FrAboutUsPage from './pages/fr/FrAboutUsPage';
import FrContactPage from './pages/fr/FrContactPage';
import FrProductsPage from './pages/fr/FrProductsPage';
import FrTermsAndServicesPage from './pages/fr/FrTermsAndServicesPage';


import ImageLicencePage from './pages/global/ImageLicencePage';
import DashboardPage from './pages/global/DashboardPage';
import LoginPage from './pages/global/LoginPage'
import UpdateProfilePage from './pages/global/UpdateProfilePage';
import AppointmentAdminPage from './pages/global/AppointmentAdminPage';
import AppointmentClientPage from './pages/global/AppointmentClientPage';

import { AuthProvider } from './context/AuthContext';

import PrivateRoute from './components/global/PrivateRoute';
import AdminHeader from './components/global/AdminHeader';

import ContactServicePage from './pages/global/ContactServicePage'

import ProductsData from './data/products.json'
import ExamplesPage from './pages/global/examplesPages/ExamplesPage';
import SolsticeRendezvousPage from './pages/global/examplesPages/Solstice-RendezVous/SolsticeRendezvousPage';
import SolsticeAdminPage from './pages/global/examplesPages/Solstice-RendezVous/SolsticeAdminPage.jsx';
import ManagementSystemPage from './pages/global/ProWebSolutionsManagementSystem/ManagementSystemPage'


function App() {

  const Languages = [
    {value: 'English',path: '/en/home', flag: ''},
    {value: 'Français',path: '/fr/home', flag: ''}
  ]



  return (
    <HashRouter>

      <AuthProvider>
        <Routes>
          <Route exact path='/' element={<HomePage/>}/>

          <Route exact path='/en/home' element={<HomePage/>}/>
          <Route exact path='/en/about' element={<AboutUsPage/>}/>
          <Route exact path='/en/contact' element={<ContactPage/>}/>
          <Route exact path='/en/products' element={<ProductsPage/>}/>
          <Route exact path='/en/basicpackage' element={<ContactServicePage Language={'en'} PackageData={ProductsData['en']} WantedPackage={'Basic'} />} />
          <Route exact path='/en/propackage' element={<ContactServicePage Language={'en'} PackageData={ProductsData['en']} WantedPackage={'Pro'} />} />
          <Route exact path='/en/businesspackage' element={<ContactServicePage Language={'en'} PackageData={ProductsData['en']} WantedPackage={'Business'} />} />
          
          
          <Route exact path='/fr/home' element={<FrHomePage/>}/>
          <Route exact path='/fr/about' element={<FrAboutUsPage/>}/>
          <Route exact path='/fr/contact' element={<FrContactPage/>}/>
          <Route exact path='/fr/products' element={<FrProductsPage/>}/>
          <Route exact path='/fr/basicpackage' element={<ContactServicePage Language={'fr'} PackageData={ProductsData['fr']} WantedPackage={'Basic'} />} />
          <Route exact path='/fr/propackage' element={<ContactServicePage Language={'fr'} PackageData={ProductsData['fr']} WantedPackage={'Pro'} />} />
          <Route exact path='/fr/businesspackage' element={<ContactServicePage Language={'fr'} PackageData={ProductsData['fr']} WantedPackage={'Business'} />} />

          {/* <Route exact path='/fr/terms&services' element={<FrTermsAndServicesPage/>}/> */}
          

          <Route exact path='/en/images/licences' element={<ImageLicencePage/>}/>
          
          <Route exact path='/terms&services' element={<TermsAndServicesPage/>}/>

          <Route path='/login' element={<LoginPage/>}/>
          <Route path='/dashboard' element={
            <PrivateRoute>
              <AdminHeader/>
              <DashboardPage/>
            </PrivateRoute>
          }  />
          <Route path='/dashboard/update-profile' element={
            <PrivateRoute>
              <AdminHeader/>
              <UpdateProfilePage/>
            </PrivateRoute>
          }  />
          <Route path='/appointment-system/admin-view' element={
            <PrivateRoute>
              <AdminHeader/>
              <AppointmentAdminPage/>
            </PrivateRoute>
          }  />
          <Route path='/appointment-system/client-view' element={
            <PrivateRoute>
              <AdminHeader/>
              <AppointmentClientPage/>
            </PrivateRoute>
          }  />


          <Route path='/examples' element={
            <PrivateRoute>
              <AdminHeader/>
              <ExamplesPage/>
            </PrivateRoute>
          }  />
          <Route path='/examples/solstice-rendezvous' element={
            <PrivateRoute>
              <AdminHeader/>
              <SolsticeRendezvousPage/>
            </PrivateRoute>
          }  />
          <Route path='/examples/solstice-rendezvous-admin' element={
            <PrivateRoute>
              <AdminHeader/>
              <SolsticeAdminPage/>
            </PrivateRoute>
          }  />
          <Route path='/management-system' element={
            <PrivateRoute>
              <AdminHeader/>
              <ManagementSystemPage/>
            </PrivateRoute>
          }  />




        </Routes>
      </AuthProvider>
  
      <Footer/>
    
    </HashRouter>
  );
}

export default App;
