import { Input, Textarea } from '@material-tailwind/react'
import React, { useState } from 'react'

import Image from '../../resources/time-management.jpg'
import addAppointment from '../../firebase_appointmentSystem'
import { serverTimestamp } from 'firebase/firestore'

export default function AppointmentClientPage() {
  return (
    <div className='bg-[var(--colorSecondary)]'>

        <div className='container mx-auto py-10 '>    
            <div className='grid grid-cols-2 bg-white '>
                <div className='max-w-[550px] my-auto'>
                    <img src={Image} alt='time management icon'/>
                </div>
                <AppointmentClientCard/>
            </div>
        </div>

    </div>
  )
}



const AppointmentClientCard = () => {

    const [email,setEmail] = useState("");
    const [appointment_date,setAppointment_date] = useState("");
    const [appointment_hour,setAppointment_hour] = useState("");
    const [persons_number,setPersons_number] = useState("");
    const [comments,setComments] = useState("");
    const [added_time,setAdded_time] = useState("");

    const [appointment_state,setAppointment_state] = useState("");



    async function clearForm(waitingSeconds,e,setState){
    
        function timeout(delay){
            return new Promise(res => setTimeout(res,delay));
        }
        await timeout(waitingSeconds*1000);
        e.target.reset()
        setState("")
    }

    const addAppointmentToFirestore = (e) => {
        setAdded_time(serverTimestamp());   //using firebase server timestamp

        const data = {
            email : email,
            appointment_date : appointment_date,
            appointment_hour : appointment_hour,
            persons_number : persons_number,
            comments : comments,
            added_time : added_time,
            appointment_verified : false,
            accepted : false,
            refused : false
        }

        let response = addAppointment(data);
        setAppointment_state(response)
        clearForm(3,e,setAppointment_state)
    }

    return(
        <div className='w-[550px] mx-auto my-auto '>
            <div className=' py-5 px-7'>
                <h1 className='text-[var(--colorPrimary)] text-3xl font-bold pb-5'>Take an Appointment</h1>
                <form className='grid gap-5' onSubmit={addAppointmentToFirestore}>
                    <p className='text-sm text-gray-600'>{appointment_state}</p>
                    <div className='w-72 '>
                        <Input type='email' required label='Email' id='user_email' onChange={(e)=> {setEmail(e.target.value)}} />
                    </div>
                    <div className='w-48'>
                        <Input type='date' min={new Date().toISOString().split("T")[0]} required label='Appointment Date' id='user_appointment_date' onChange={(e)=> {setAppointment_date(e.target.value)}} className='text-center'/>
                    </div>
                    <div className='w-48'>
                        <Input type='time' required label='Appointment Hour' id='user_appointment_hour' onChange={(e)=> {setAppointment_hour(e.target.value)}} className='text-center'/>
                    </div>
                    <div className='w-48'>
                        <Input type='number' required label='Persons Number' id='user_persons_number' onChange={(e)=> {setPersons_number(e.target.value)}} className='text-center' />
                    </div>
                    
                    <div className='mr-20 '>
                        <Textarea label='Comments' id='user_comments' onChange={(e)=> {setComments(e.target.value)}}/>
                    </div>
                    <div>
                        <button className='button-type-2 text-white py-3 px-5' type='submit'>Submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}